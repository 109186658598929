<template>
  <div class="h5SingleCenter">
    <van-nav-bar
      title="店内成长套系"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <div class="searchBox">
      <span :class="['iconBtn',{'beingClick':beingClick}]">
        <i :style="{transform:beingClick?'rotate(90deg)':'rotate(-360deg)'}" @click="beingClick=!beingClick;" class="el-icon-s-unfold"></i>
      </span>
      <van-search
        @click="beingClick=false;"
        :class="['vanSearch']"
        v-model="formInline.keyWord"
        background="#2c80c5"
        shape="round"
        @input="search"
        placeholder="请输入 姓名/手机号/备注"
      />
    </div>
    <!-- <van-pagination v-model="pages.page" @change="changePage" mode="simple"  :total-items="pages.total*1" :items-per-page="pages.pageNum*1" /> -->
    <div class="cellDiv" style="background: #4b95d3; color: #fff">
      <span>宝宝姓名</span>
      <span>手机号</span>
      <span>套系金额</span>
      <span>备注</span>
    </div>
    <!-- 列表 -->
    <van-pull-refresh class="cellBox" v-model="tableLoading" @refresh="search">
      <div
        class="cellDiv"
        v-for="item in tableData"
        :key="item._id"
        @click="editConsumer(item)"
      >
        <span>{{ item.name }}</span>
        <span>{{ item.phone }}</span>
        <span>{{ item.orderPrice }}</span>
        <span>{{ item.comment }}</span>
      </div>
      <div class="noneData" v-if="tableData.length == 0" >
        <i class="el-icon-coffee"></i>
        暂无数据
      </div>
    </van-pull-refresh>
    <el-pagination
        background
        small
        layout="total,prev, pager, next"
        :current-page="pages.page * 1"
        :total="pages.total"
        :page-size="pages.pageNum * 1"
        style="margin:10px;text-align:center;"
        @size-change="changePageSize"
        @current-change="changePage"
      >
      </el-pagination>
    <!-- 遮罩层 -->
    <van-overlay :show="dialogVisible" @click="dialogVisible = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="main">
            <div class="wrapHeader">
              <span class="title">{{ isEdit ? "正在编辑" : "查看详情" }}</span>
              <van-button type="primary" @click="wantEdit">{{
                isEdit ? "取消编辑" : "编辑"
              }}</van-button>
            </div>
            <!-- 编辑 -->
            <van-cell-group v-if="isEdit">
              <van-field
                v-model="form.parentName"
                label="家长姓名"
                :center="true"
                size="large"
                placeholder="请输入"
              />
              <van-field
                v-model="form.name"
                label="宝宝姓名"
                :center="true"
                size="large"
                placeholder="请输入"
              />
              <van-field
                v-model="form.phone"
                label="手机号"
                :center="true"
                size="large"
                placeholder="请输入"
              />
              <van-field
                v-model="form.orderDate"
                label="订单日期"
                :readonly="true"
                @click="chooseDate(form.orderDate, 'orderDate')"
                :center="true"
                size="large"
                placeholder="请输入"
              />
              <van-field
                v-model="form.pickUpDate"
                label="取件日期"
                :readonly="true"
                @click="chooseDate(form.pickUpDate, 'pickUpDate')"
                :center="true"
                size="large"
                placeholder="请输入"
              />
              <van-field
                v-model="form.orderPrice"
                label="订单金额"
                :center="true"
                size="large"
                placeholder="请输入"
              />
              <van-field
                v-model="form.comment"
                rows="1"
                autosize
                label="备注"
                type="textarea"
                label-width="6.2rem"
                placeholder="请输入"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="进度:"
              >
              <template #right-icon>
                <el-button type="success" icon="el-icon-plus" circle @click="addProgress"></el-button>
                <el-button type="danger" icon="el-icon-delete" circle @click="deleteProgress"></el-button>
              </template>
              </van-cell>
                <van-field 
                  v-for="(item,ind) in form.progress" 
                  :key="ind"
                  v-model="item.title" 
                  placeholder="请输入进度内容" 
                  size="large" 
                  :label="+ind + 1" />
            </van-cell-group>
            <!-- 展示详情 -->
            <van-cell-group v-else>
              <van-cell
                :clickable="true"
                size="large"
                title="家长姓名"
                :value="form.parentName"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="宝宝姓名"
                :value="form.name"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="手机号"
                :value="form.phone"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="订单日期"
                :value="form.orderDate"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="取件日期"
                :value="form.pickUpDate"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="套系金额"
                :value="form.orderPrice"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="备注"
                :value="form.comment"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="进度:"
              />
              <van-steps direction="vertical" :active="form.progress?+form.progress.length-1:0">
                <van-step v-for="(item,ind) in form.progress" :key="ind">
                  <p>{{item.title}}</p>
                </van-step>
              </van-steps>
            </van-cell-group>
          </div>
          <div class="btnDiv" v-if="isEdit">
            <van-button
              class="btn"
              block
              @click="dialogVisible = false"
              plain
              type="primary"
              color="#337d56"
              style="
                border: none;
                border-radius: 0;
                border-top: 1px solid #2c80c5;
              "
              >取消</van-button
            >
            <van-button
              class="btn"
              type="primary"
              block
              @click="onEdit"
              color="#2c80c5"
              style="border: none; border-radius: 0"
              >保存</van-button
            >
          </div>
          <div class="btnDiv" v-else>
            <van-button
              class="btn"
              type="primary"
              block
              @click="dialogVisible = false"
              color="#2c80c5"
              style="border: none; border-radius: 0"
              >关闭</van-button
            >
          </div>
        </div>
        <!-- 时间选器 -->
        <van-popup v-model="showDatePicker" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :minDate="minDate"
            @cancel="showDatePicker = false"
            @confirm="confirmDate()"
          />
        </van-popup>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "H5InnerGrowUpList",
  components: {},
  data() {
    return {
      formInline: {
        parentName: "",
        name: "",
        phone: "",
        keyWord: "",
        pickUpDate: ""
      },
      pages: {
        page: 1,
        pageNum: 15,
        total: 0,
      },
      showDatePicker: false,
      currentDate: "",
      minDate: new Date(2000, 0, 1),
      dateKey: "", // 区分时间选择器
      tableData: [],
      dialogVisible: false,
      // 添加导入数据
      form: {
        _id: "",
        parentName: "",
        name: "",
        phone: "",
        orderDate: "",
        orderPrice: "",
        comment: "",
        progress: "",
        pickUpDate: ""
      },
      isEdit: false, // 判断是否为编辑
      tableLoading: false,
      btnLoading: false,
      oldForm: {},
      beingClick:false,
    };
  },
  created() {},
  mounted() {
    this.formInline.keyWord = this.$route.query.name?this.$route.query.name:this.$route.query.keyword?this.$route.query.keyword:'';
    this.getData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    search() {
      this.pages.page = 1;
      this.getData();
    },
    changePage(val) {
      this.pages.page = val * 1;
      this.getData();
    },
    // 选择日期
    chooseDate(date = "", key) {
      console.log(key)
      this.showDatePicker = true;
      if (date && date.trim() !== "") {
        this.currentDate = new Date(date.split("-"));
      } else {
        this.currentDate = new Date();
      }
      this.dateKey = key;
    },
    // 确定日期
    confirmDate() {
      if (this.dateKey == "") return;
      let year = new Date(this.currentDate).getFullYear();
      let month = new Date(this.currentDate).getMonth() * 1 + 1;
      month = month < 10 ? "0" + month : month;
      let day = new Date(this.currentDate).getDate();
      day = day < 10 ? "0" + day : day;
      this.form[this.dateKey] = year + "-" + month + "-" + day;
      this.showDatePicker = false;
      this.dateKey = "";
    },
    // 获取数据
    async getData() {
      this.tableLoading = true;
      await axios
        .get(
          this.$baseUrl +
            "/innerGrowUp?" +
            "page=" +
            this.pages.page +
            "&pageNum=" +
            this.pages.pageNum +
            "&parentName=" +
            this.formInline.parentName +
            "&name=" +
            this.formInline.name +
            "&phone=" +
            this.formInline.phone +
            "&pickUpDate=" +
            this.formInline.pickUpDate +
            "&keyWord=" +
            this.formInline.keyWord
        )
        .then((res) => {
          setTimeout(() => {
            this.tableLoading = false;
          }, 200);
          this.tableData = res.data.list;
          this.pages = res.data.pages;
          this.pages.page = res.data.pages.page * 1;
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    //导出excel
    exportExcel() {
      axios
        .get(
          this.$baseUrl +
            "/innerGrowUp/exportExcel?" +
            "page=" +
            this.pages.page +
            "&pageNum=" +
            this.pages.pageNum +
            "&parentName=" +
            this.formInline.parentName +
            "&name=" +
            this.formInline.name +
            "&phone=" +
            this.formInline.phone +
            "&pickUpDate=" +
            this.formInline.pickUpDate +
            "&keyWord=" +
            this.formInline.keyWord
        )
        .then((res) => {
          console.log("导出");
          window.open(res.data.url);
        });
    },
    // 点击导入
    openAdd() {
      this.form = {
        _id: "",
        parentName: "",
        name: "",
        phone: "",
        orderDate: "",
        orderPrice: "",
        comment: "",
        pickUpDate: "",
        progress: "",
      };
      this.dialogVisible = true;
      this.isEdit = false;
    },
    // 点击编辑按钮
    wantEdit() {
      this.isEdit = !this.isEdit;
      if (!this.isEdit) {
        this.form = { ...this.oldForm };
        console.log(this.oldForm, "old", this.form);
      }
    },
    // 点击列表，展示用户信息
    editConsumer(scope) {
      this.isEdit = false;
      this.dialogVisible = true;
      let { pickUpDate, parentName, name, phone, orderDate, orderPrice, progress, comment, _id } =
        scope;
      this.form = {
        parentName,
        name,
        phone,
        orderDate,
        orderPrice,
        comment,
        pickUpDate,
        progress: JSON.parse(JSON.stringify(progress)),
        _id,
      };
      // 暂存编辑数据
      this.oldForm = { ...this.form };
      console.log(this.form);
    },
    // 确认添加
    onSubmit() {
      this.btnLoading = true;
      let param = { ...this.form };
      delete param._id;
      console.log(param, "新增");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/innerGrowUp/addConsumer", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "添加成功");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "添加成功！",
              type: "success",
            });
            this.dialogVisible = false;
            this.btnLoading = false;
            this.search();
          }
          if (res.data.code == 302) {
            this.$message({
              duration: "2000",
              showClose: true,
              message: "重复手机号请检查输入信息！",
              type: "warning",
            });
            this.btnLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    // 确认编辑
    onEdit() {
      this.btnLoading = true;
      let param = { ...this.form };
      console.log(param, "编辑");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/innerGrowUp/editInfo", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "修改数据");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "修改成功！",
              type: "success",
            });
            this.btnLoading = false;
            this.search();
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    addProgress() {
      this.form.progress.push({title:''})
    },
    deleteProgress() {
      this.form.progress.pop();
    },
    // 修改每页选择数量
    changePageSize(val) {
      this.pages.pageNum = val;
      this.getData();
    },
  },
};
</script>
<style scoped lang="less">
.h5SingleCenter {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .searchBox {
    display: flex;
    .iconBtn{
      padding-left: 10px;
      height: 54px;
      width: 46px;
      line-height: 54px;
      font-size: 30px;
      transition: all .5s ease-in-out 0s;
      overflow: hidden;
      i{
        transition: all .5s ease-in-out 0s;
      }
    }
    .beingClick{
      width: 90%;
    }
    .vanSearch{
      min-width: 60px;
      flex: 1;
      width:63px;
      transition: all .5s ease-in-out 0s;
    }
  }
  .cellBox {
    overflow-y: scroll;
    flex: 1;
    background: #d8e8f557;
    .cellDiv:nth-child(1) {
      border-top: 1px solid #d9dbdf;
    }
  }
  .cellDiv {
    border-bottom: 1px solid #fef3c9;
    padding: 7px 0;
    display: flex;
    span {
      height: 25px;
      line-height: 25px;
      display: inline-block;
      margin-left: 12px;
    }
    span:nth-child(1) {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 65px;
    }
    span:nth-child(2) {
      width: 105px;
      margin-left: 0px;
      overflow: hidden;
    }
    span:nth-child(3) {
      width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span:nth-child(4) {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .vanTag {
      width: 38px !important;
      text-align: center;
      margin-left: 0;
    }
  }
  .cellDiv:hover {
    background-color: #e0e2eb;
  }
  .wrapper {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 50px 10px;
    .block {
      display: flex;
      flex-direction: column;
      // height: 100%;
      width: 100%;
      background-color: #fff;
      .main {
        flex: 1;
        .wrapHeader {
          display: flex;
          .title {
            flex: 1;
            padding: 0 20px 0;
            text-align: center;
            font-weight: bold;
            line-height: 44px;
            font-size: 18px;
          }
        }
      }
    }
    .radioSingle {
      margin-bottom: 10px;
    }
  }
  .btnDiv {
    margin-top: 5px;
    display: flex;
    .btn {
      flex: 1;
    }
  }
  .noneData {
    height: 300px;
    line-height: 300px;
    width: 100%;
    text-align: center;
  }
}
</style>